import { Injectable } from '@angular/core';
import { CommandService, ICommandResponse } from '@core/services/command.service';
import { QueryService, IQueryResponse } from '@core/services/query.service';

export enum ClaimStatus
{
    Unverified = -7,
    Verified = -6,
    Inprogress = -5,
    Investigating = -4,
    Rejected = -3,
    Cancelled = -2,
    Disputed = -1,
    Pending = 0,
    Approved = 1,
    Paid = 2
}


export interface ClaimVerificationFormSubmit
{
    claimId: string;
    verifierId: string;
    verifiedAt: Date;
    verifierStory: string;
    additionalInfo: string;
    levelOfKnowledge: string;
    levelOfTrustworthiness: string;
    recommendation: string;
    verifiedBy: string;
}

export interface CollectiveClaimModel 
{
    id?: string;
    createdAt: Date;
    userId?: string;
    policyId?: string;
    productName?: string;
    amount: number;
    currency?: string;
    status: ClaimStatus;
    paidAt?: Date;
    beneficiaryName?: string;

}

export interface CollectiveClaimsPayload
{
    collectiveId: string;
    searchString?: string;
}

export interface ClaimsQueryPayload
{
    collectiveId?: string;
    searchString?: string;
    orderBy?: string;
    sortBy?: string;
    status?: ClaimStatus;
    skip: number;
    take: number;
}

export interface ClaimsQueryClaimModel
{
    id: string;
    userId: string;
    policyId: string;
    productName: string;
    collectiveId: string;
    collectiveName: string;
    beneficiaryId: string;
    beneficiaryName: string;
    amount: number;
    currency: string;
    status: ClaimStatus;
    paidAt: Date;
    createdAt: Date;
    hours: number;
}

export interface DetailedModel
{
    id: string;
    policyId: string;
    policyNumber: string;
    isForJunior: boolean;
    productName: string;
    productType: string;
    orderName: string;
    collectiveId: string;
    collectiveName: string;
    policyholderId: string;
    policyholderName: string;
    policyholderNationalId: string;
    policyholderPhone: string;
    policyholderDateOfBirth?: Date;
    beneficiaryId: string;
    beneficiaryName: string;
    beneficiaryNationalId: string;
    beneficiaryPhone: string;
    beneficiaryDateOfBirth?: Date;
    beneficiaryRel?: string;
    dependantId?: string;
    dependantName?: string;
    dependantNationalId?: string;
    dependantPhone?: string;
    dependantDateOfBirth?: Date;
    payoutAmount: number;
    currency: string;
    paidAt?: Date;
    policyValidFrom?: Date;
    policyValidTo?: Date;
    createdAt?: Date;
    hospital?: string;
    hospitalGeolocation?: string;
    properties: Record<string, any>; // Replacing IDictionary<string, object>
    policyProperties: Record<string, any>; // Replacing IDictionary<string, object>
}



export interface ValidatorModel
{
    validatorId: string;
    userId: string;
    name: string;
    phoneNumber: string;
    respondedAt?: Date;
    response?: string;
    createdAt: Date;
    verificationFormId: string;
}

export interface DocumentModel
{
    documentId: string;
    createdAt?: Date;
    title: string;
    contents: string;
    userId: string;
    author: string;
}

export interface HistoryModel
{
    id: string;
    startDate?: Date;
    endDate?: Date;
    amount: number;
    status: ClaimStatus;
    createdAt?: Date;
    paidAt?: Date;
    duration: number;
}

export interface ClaimStatusModel
{
    id: string;
    createdAt: Date;
    userId: string;
    name: string;
    description: string;
    status: ClaimStatus;
}

export interface ClaimVerificationMembersModel
{
    validatorId: string;
    userId: string;
    name: string;
    phoneNumber: string;
    respondedAt?: Date;
    response?: string;
    createdAt: Date;
    verificationFormId: string;
}

export interface ClaimVerificationFormsModel
{
    verificationFormId: string;
    verifierId: string;
    name: string;
    phoneNumber: string;
    verifierStory: string;
    additionalInfo: string;
    levelOfKnowledge: string;
    levelOfTrustworthiness: string;
    recommendations: string;
    verifiedAt: Date;
    createdAt: Date;
    verifiedBy: string;
}


export interface ClaimQueryResponse
{
    claim: DetailedModel;
    validators: ValidatorModel[];
    documents: DocumentModel[];
    history: HistoryModel[];
    claimStatus: ClaimStatusModel[];
    claimVerificationMembers: ClaimVerificationMembersModel[];
    claimVerificationForms: ClaimVerificationFormsModel[];
}

export interface ClaimsQueryResponse
{
    claims: ClaimsQueryClaimModel[];
    claimsHighlights: ClaimsQueryClaimModel[];
    totalCount: number;
}

export interface ClaimPolicyModel
{
    id: string;
    createdAt: Date;
    productName: string;
    productType: string;
    validFrom: Date;
    validTo: Date;
    policyholderName: string;
    policyholderNationalId: string;
    policyholderPhone: string;
    beneficiaryId: string;
    dependantName: string;
    countryId: string;
    isLifePolicy: boolean;
}

export interface ClaimPoliciesQueryResponse
{
    policies: ClaimPolicyModel[];
    userData: Record<string, any>;
}

export interface ClaimSubmitPayload
{
    policyId: string;
    userId: string;
    data: Record<string, any>;
}


@Injectable({ providedIn: 'root' })
export class ClaimRepository
{
    constructor(
        private queryService: QueryService,
        private commandService: CommandService
    ) { }

    public async getClaimsAsync(data: ClaimsQueryPayload): Promise<IQueryResponse<ClaimsQueryResponse>>
    {
        return await this.queryService.queryAsync<any>('ClaimsQuery', data);
    }

    public async getClaim(claimId: string): Promise<IQueryResponse<ClaimQueryResponse>>
    {
        return await this.queryService.queryAsync<any>('ClaimQuery', { claimId });;
    }

    public async getClaimPolicies(userId: string): Promise<IQueryResponse<ClaimPoliciesQueryResponse>>
    {
        return this.queryService.queryAsync<ClaimPoliciesQueryResponse>('ClaimPoliciesQuery', { userId });
    }

    public async submitNote(data: any): Promise<ICommandResponse>
    {
        return this.commandService.publishAsync('SubmitClaimNote', data);
    }

    public async setStatus(data: any): Promise<ICommandResponse>
    {
        return this.commandService.publishAsync('ClaimSetStatus', data);
    }

    public async makePayout(claimId: string): Promise<ICommandResponse>
    {
        return this.commandService.publishAsync('ClaimMakePayout', { claimId });
    }

    public async claimSubmit(data: ClaimSubmitPayload): Promise<ICommandResponse>
    {
        return this.commandService.publishAsync('claimSubmit', data)
    }

    public async claimVerificationSubmit(data: ClaimVerificationFormSubmit): Promise<ICommandResponse>
    {
        return this.commandService.publishAsync('ClaimVerificationFormSubmit', data)
    }

    public getCollectiveClaims(data: CollectiveClaimsPayload): Promise<IQueryResponse<CollectiveClaimModel[]>>
    {
        return this.queryService.queryAsync('CollectiveClaimsQuery', data);
    }

    public commentSubmit(data: any): Promise<ICommandResponse>
    {
        const commandName = data.documentId
            ? 'DocumentUpdate'
            : 'DocumentCreate';

        return this.commandService.publishAsync(commandName, data);
    }
}