<form [formGroup]="form" (submit)="submit($event)">

    <div class="tw-flex tw-flex-col">

        <mat-form-field class="tw-flex">
            <mat-label *ngIf="parent.isForJunior">Birth Certificate number</mat-label>
            <mat-label *ngIf="parent.isForSpouse">Spouse National ID</mat-label>
            <input matInput formControlName="idNumber" />
            <mat-error>Required</mat-error>
        </mat-form-field>

        <mat-form-field class="tw-flex" *ngIf="skipIprs">
            <mat-label>Name of child</mat-label>
            <input matInput formControlName="name" />
            <mat-error>Name of child is required</mat-error>
        </mat-form-field>

        <mat-form-field class="tw-flex" *ngIf="skipIprs">
            <mat-label>Date of birth</mat-label>
            <input formControlName="dateOfBirth" matInput readonly (focus)="picker.open()" [min]="minDate"
                [max]="maxDate" [matDatepicker]="picker" />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker startView="multi-year" #picker></mat-datepicker>
            <mat-error>Date of birth is required</mat-error>
        </mat-form-field>

        <mat-form-field class="tw-flex" *ngIf="form.get('phone')">
            <mat-label>{{parent.isForSpouse ? 'Spouse Phone' : 'Beneficiary Phone'}}</mat-label>
            <input matInput formControlName="phone" />
            <mat-error>Invalid phone number</mat-error>
        </mat-form-field>

        <div class="message tw-mb-4" *ngIf="message">{{message}}</div>
    </div>

    <div class="tw-flex tw-flex-row tw-justify-between">

        <button type="button" class="tw-btn" (click)="previous()" [disabled]="ignorePrevious">
            Previous
        </button>

        <button type="submit" class="tw-btn" [disabled]="clickedNext">
            Next
        </button>

    </div>

</form>