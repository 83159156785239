import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AbstractControl, ControlContainer, ControlValueAccessor, UntypedFormControl, FormControlDirective, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ListSelectorDialog, ListSelectorDialogData } from '@shared/components/select-items/select-items.dialog';

export interface ListSelectorConfig
{
    queryName: string;
    queryParams?: any;
    responseProp?: string;
    txtNoSelection?: string;
    title?: string;
}

@Component({
    selector: 'ddf-list-selector',
    templateUrl: './ddf-list-selector.component.html',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: ListSelectorComponent,
        multi: true
    }],
    inputs: ['formControlName', 'formControl', 'config'],
    standalone: false
})
export class ListSelectorComponent implements ControlValueAccessor
{
    @ViewChild(FormControlDirective)
    public formControlDirective: FormControlDirective;
    public formControl: UntypedFormControl;
    public formControlName: string;
    private items: { id: string, name: string }[] = [];
    private config: ListSelectorConfig | undefined;

    public get control(): AbstractControl
    {
        return this.formControl ||
            this.controlContainer.control.get(this.formControlName);
    }

    public get btnText(): string
    {
        return this.items.length <= 0
            ? this.config?.txtNoSelection || 'No Selection'
            : this.items.map((x: any) => x.name).join(',');
    }

    constructor(
        private controlContainer: ControlContainer,
        private dialog: MatDialog) { }

    registerOnTouched(fn: any): void
    {
        if (!this.formControlDirective) return;
        this.formControlDirective.valueAccessor.registerOnTouched(fn);
    }

    registerOnChange(fn: any): void
    {
        if (!this.formControlDirective) return;
        this.formControlDirective.valueAccessor.registerOnChange(fn);
    }

    writeValue(obj: any): void
    {
        if (!obj && this.control)
        {
            this.items = [];
        }

        if (!this.formControlDirective) return;
        this.formControlDirective.valueAccessor.writeValue(obj);
    }

    setDisabledState(isDisabled: boolean): void
    {
        if (!this.formControlDirective) return;
        this.formControlDirective.valueAccessor.setDisabledState(isDisabled);
    }

    public async openAsync(): Promise<void>
    {
        const data: ListSelectorDialogData = 
        {
            queryName: this.config.queryName,
            queryParams: this.config.queryParams,
            responseProp: this.config.responseProp,
            selected: Array.from(this.items),
            title: this.config.title,
        };

        const response = await this.dialog
            .open(ListSelectorDialog, {
                data
            })
            .afterClosed()
            .toPromise();

        if (response)
        {
            this.items = response;
            const ids = this.items.map((x: any) => x.id);
            this.control.setValue(ids);
        }
    }
}
