<form [formGroup]="form" (submit)="submit($event)">

    <div class="layout-column">

        <mat-form-field class="flex" *ngIf="!(this.parent.isForJunior && countryCode==='BI')">
            <mat-label>{{parent.isForJunior ? ('Birth Certificate Number' )
                : parent.isForSpouse ? ('Spouse National ID' ) : ('nationalID' )}}</mat-label>
            <input type="text" matInput formControlName="idNumber" />
            <mat-error>Required</mat-error>
        </mat-form-field>

        <mat-form-field class="flex">
            <mat-label>First name</mat-label>
            <input matInput formControlName="firstName" />
            <mat-error>First name is required</mat-error>
        </mat-form-field>

        <mat-form-field class="flex">
            <mat-label>Last name</mat-label>
            <input matInput formControlName="familyName" />
            <mat-error>Last name is required</mat-error>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Date of birth</mat-label>
            <input formControlName="dateOfBirth" matInput readonly (focus)="picker.open()" [max]="maxDate"
                [min]="minDate" [matDatepicker]="picker" />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker startView="multi-year" #picker></mat-datepicker>
            <mat-error>Date of birth is required</mat-error>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Select gender</mat-label>
            <mat-select formControlName="gender">
                <mat-option value="1">Male</mat-option>
                <mat-option value="2">Female</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="flex" *ngIf="parent.isForSpouse">
            <mat-label>Phone number</mat-label>
            <input type="tel" placeholder="" matInput formControlName="phone">
        </mat-form-field>

        <div class="message is-error mb-15" *ngIf="message">{{message}}</div>
    </div>

    <div class="tw-flex tw-flex-row tw-justify-between">

        <button type="button" class="tw-btn" (click)="previous()" [disabled]="ignorePrevious">
            Previous
        </button>

        <button type="submit" class="tw-btn" [disabled]="clickedNext">
            Next
        </button>
    </div>

</form>