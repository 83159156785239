import { Component } from '@angular/core';
import { Identity, TokenService } from '@core/services/token.service';
import { MenuLink, UiService } from '@core/providers/ui.service';


@Component({
    templateUrl: './dashboard.component.html',
    styles: [],
    standalone: false
})
export class DashboardComponent
{

    public identity: Identity | undefined;
    public menuLinks: MenuLink[] | undefined;

    constructor(
        private uiService: UiService,
        private tokenService: TokenService)
    {

    }

    public async ngOnInit(): Promise<void>
    {
        this.uiService.removeFilterSettingsFromLocalStorage();
        this.identity = this.tokenService.getIdentity();
        this.menuLinks = this.uiService.getMenuLinks();
    }
}
