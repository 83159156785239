import { CommandService, ICommandResponse } from '@/core/services/command.service';
import { Injectable } from '@angular/core';
import { QueryService, IQueryResponse } from '@core/services/query.service';

export interface ReportsModel
{
    id: string;
    createdAt: Date;
    type: string;
    name: string;
    summary: string;
    access_level: number;
}


export enum DisplayType
{
    Tabular = 1,
    Chart = 2
}

export interface ReportSave
{
    reportId: string;
    name: string;
    summary?: string;
    displayAs: DisplayType;
    chartType?: string;
    queryExpr?: string;
    filters: any[];
}

@Injectable({ providedIn: 'root' })
export class ReportRepository
{
    constructor(private commandService: CommandService,
        private queryService: QueryService,
    )
    { }

    public getReports(): Promise<IQueryResponse<ReportsModel[]>>
    {
        return this.queryService.queryAsync('GetReports', {});
    }

    public async saveReport(report: ReportSave): Promise<ICommandResponse>
    {
        return this.commandService.publishAsync('ReportSave', report);
    }
}
