import { Injectable } from '@angular/core';
import { CommandService, ICommandResponse } from '@core/services/command.service';
import { QueryService, IQueryResponse } from '@core/services/query.service';

export interface ProductModel
{
    id: string;
    createdAt: Date;
    name: string;
    productType: string;
    price: number;
    currency: string;
    underwriter: string;
    level: number;
    description: string;
    canDefault: boolean;
    summary: any;
    properties: ProductPropertiesModel;
    productTags: string[];
    isFamily: boolean;
    underwriterId: string;
    canExtend: boolean;
}

export interface ProductPropertiesModel
{
    isBeneficiaryRequired: boolean;
    maxClaimsAllowed: number;
}

export interface ProductQueryModel
{
    product: ProductModel;
    addons: ProductModel;
}

export interface AvailableProduct
{
    id: string;
    createdAt: Date;
    name: string;
    price: number;
    servicefee: number;
    totalProductPrice: number;
    currency: string;
    productType: string;
    summary: { included: string[]; excluded: string[]; }
    description: string;
    properties: { level: number; };
}

export interface AvailableProductsQueryResult
{
    availableProducts: AvailableProduct[];
    currentProducts: [];
    renewableProducts: [];
    hasPendingOrders: boolean;
    canRenew: boolean;
}

export interface AvailableProductsPayload
{
    userId?: string;
    productId?: string;
    collectiveId?: string;
}

export interface ProductQuery
{
    productId: string;
}

export interface ProductsQueryProductModel 
{
    id: string;
    name: string;
    description: string;
    productType: string;
    currency: string;
    price: number;
}


export interface ProductsQueryResponse
{
    products: ProductsQueryProductModel[];
}

@Injectable({ providedIn: 'root' })
export class ProductRepository
{
    constructor(
        private commandService: CommandService,
        private queryService: QueryService,
    ) { }

    public getProducts(underwriterId: String): Promise<IQueryResponse<ProductsQueryResponse>>
    {
        return this.queryService.queryAsync('ProductsQuery', { underwriterId });
    }

    public create(data: any): Promise<ICommandResponse>
    {
        return this.commandService.publishAsync('ProductCreate', data);
    }

    public update(data: any): Promise<ICommandResponse>
    {
        return this.commandService.publishAsync('ProductUpdate', data);
    }

    public getAvailableProducts(data: AvailableProductsPayload): Promise<IQueryResponse<AvailableProductsQueryResult>>
    {
        return this.queryService.queryAsync<AvailableProductsQueryResult>('AvailableProductsQuery', data);
    }

    public getProduct(data: ProductQuery): Promise<IQueryResponse<ProductQueryModel>>
    {
        return this.queryService.queryAsync<ProductQueryModel>('ProductQuery', data);
    }
    public getTrainerProduct(data: any): Promise<any>
    {
        return this.queryService.queryAsync('TrainerProductQuery', data);
    }

}
