import { Injectable } from '@angular/core';
import { CommandService, ICommandResponse } from '@core/services/command.service';
import { QueryService, IQueryResponse } from '@core/services/query.service';

export interface PolicyModel 
{
    id: string;
    policyNumber: string;
    policyholderId: string;
    policyholderName: string;
    policyholderNationalId: string;
    policyholderPhone: string;
    beneficiaryId: string;
    beneficiaryName: string;
    beneficiaryNationalId: string;
    beneficiaryPhone: string;
    productId: string;
    productName: string;
    validFrom: Date;
    validTo: Date;
    createdAt: Date;
    collectiveId: string;
    collectiveName: string;
    organizationId: string;
    organizationName: string;
    orderId: string;
    orderName: string;
    orderAmount: number;
    properties: string;
    claimMaxDays: number;
    currency: string;
    paidDays: number;
    remainingDays: number;
    dependantId: string;
    dependantName: string;
    dependantNationalId: string;
    dependantPhone: string;
    isActive: boolean;
    inWaitingPeriod: boolean;
}

export interface ClaimModel
{
    id: string;
    userId: string;
    fullName: string;
    paidAt: Date;
    createdAt: Date;
}

export interface PolicyQueryModel
{
    policy: PolicyModel;
    claims: ClaimModel[];
}

export interface PolicySetBeneficiaryPayload
{
    policyId: string;
    beneficiaryId: string;
    beneficiaryRel?: string;
}


export interface PoliciesQueryPayload
{
    searchString?: string;
    sortBy?: string;
    distributionChannelIds?: string[];
    beneficiaryDetails?: boolean;
    status?: boolean;
    orderBy?: string;
    skip?: number;
    take?: number;
}

export interface PoliciesQueryModel 
{
    id: string;
    createdAt: Date;
    policyNumber: string;
    productName: string;
    policyholderId: string;
    policyholderName: string;
    policyholderNationalId: string;
    beneficiaryId: string;
    beneficiaryName?: string;
    beneficiaryNationalId?: string;
    validFrom: Date;
    validTo: Date;
    isActive: boolean;
    inWaitingPeriod: boolean;
}

export interface PoliciesQueryResponse
{
    policies: PoliciesQueryModel[];
    totalCount: number;
}


@Injectable({ providedIn: 'root' })
export class PolicyRepository
{
    constructor(
        private queryService: QueryService,
        private commandService: CommandService,
    ) { }

    public async getPolicy(policyId: string): Promise<IQueryResponse<PolicyQueryModel>>
    {
        return await this.queryService.queryAsync<PolicyQueryModel>('PolicyQuery', { policyId });
    }

    public async getPolicies(data: PoliciesQueryPayload): Promise<IQueryResponse<PoliciesQueryResponse>>
    {
        return this.queryService.queryAsync<PoliciesQueryResponse>('PoliciesQuery', data);
    }

    public updateBeneficiary(data: any): Promise<ICommandResponse> 
    {
        return this.commandService.publishAsync('PolicySetBeneficiary', data);
    }
}
