<mat-dialog-content>
    <div class="tw-flex tw-flex-col tw-justify-between tw-items-center">
        <h3 mat-dialog-title class="">Select {{title}}</h3>

        <form [formGroup]="form">
            <mat-form-field>
                <mat-label>Search</mat-label>
                <input type="search" matInput placeholder="Search" formControlName="searchText">
                <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
        </form>
    </div>

    <ng-container *ngIf="(items | search : searchText);">

        <ddf-state [isLoading]="isLoading" [hasData]="filteredItems && filteredItems.length > 0">

            <div class="responsive-table">

                <table class="table pv-15" *ngIf="(items | search : searchText).length > 0; else noResult">
                    <tbody>
                        <tr *ngFor="let item of filteredItems | search : searchText;">
                            <td>
                                @if (data.multipleSelection)
                                {
                                <mat-checkbox [checked]="isSelected(item.id)"
                                    (change)="toggle(item.id, item.name)">{{item.name}}</mat-checkbox>
                                }
                                @else
                                {
                                <mat-radio-button [checked]="isSelected(item.id)"
                                    (change)="toggle(item.id, item.name)">{{item.name}}</mat-radio-button>

                                }
                            </td>
                        </tr>
                    </tbody>

                </table>

            </div>
        </ddf-state>

    </ng-container>


</mat-dialog-content>

<mat-dialog-actions class="tw-flex tw-justify-between tw-w-full">

    <button type="button" mat-button class=" mr-5" (click)="submit($event)">
        OK
    </button>

    <span class="ml-2"></span>

    <button type="button" mat-button mat-dialog-close>
        Cancel
    </button>

</mat-dialog-actions>