import { Component, inject } from '@angular/core';
import { FormControl, FormGroup, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { QueryService } from '@core/services/query.service';
import { debounceTime, distinctUntilChanged } from 'rxjs';

export interface ListSelectorDialogData 
{
    queryName: string;
    queryParams: any;
    responseProp?: string;
    selected: { id: string, name: string }[];
    multipleSelection?: boolean;
    title?: string;
}

@Component({
    templateUrl: './select-items.dialog.html',
    standalone: false
})
export class ListSelectorDialog
{
    public title: string;
    public items: any[];
    public selected: any[] = [];
    public filteredItems: any[] = [];
    public searchText: string;
    public isLoading: boolean = false;
    public form: UntypedFormGroup;

    private data: ListSelectorDialogData = inject(MAT_DIALOG_DATA);

    constructor(
        private dialogRef: MatDialogRef<ListSelectorDialog>,
        private queryService: QueryService
    )
    {
        this.selected = this.data.selected;
        
        this.title = this.data.title;
        this.form = new FormGroup({
            searchText: new FormControl(''),
        });

        // Listen for search text changes
        this.form.controls.searchText.valueChanges
            .pipe(debounceTime(500), distinctUntilChanged())
            .subscribe((text: string) =>
            {
                this.filterItems(text);
            });
    }

    public async ngOnInit(): Promise<void>
    {
        this.isLoading = true;
        const response = await this.queryService
            .queryAsync(this.data.queryName, this.data.queryParams || {});
        if (response && response.isSuccess)
        {
            this.items = this.data.responseProp
                ? response.result[this.data.responseProp]
                : response.result;

            this.filteredItems = [...this.items];
        }
        this.isLoading = false;
    }

    public getIndex(id: string): number
    {
        return this.selected.findIndex(
            (x: any) => x.id === id
        );
    }

    public isSelected(id: string): boolean
    {
        return this.getIndex(id) >= 0;
    }


    public toggle(id: string, name: string): void
    {
        const ix = this.getIndex(id);
        if (ix >= 0)
        {
            this.selected.splice(ix, 1);
        } else
        {
            if (this.data.multipleSelection)
            {
                this.selected.push({ id, name });
            } else
            {
                this.selected = [{ id, name }]; // Replace with a single selected item in single-selection mode
            }
        }
    }

    // Filter items based on search text
    private filterItems(searchText: string): void
    {
        const lowerSearchText = searchText.toLowerCase();
        this.filteredItems = this.items.filter((item) =>
            item.name.toLowerCase().includes(lowerSearchText)
        );
    }


    public async submit(ev: Event): Promise<void>
    {
        ev.preventDefault();
        this.dialogRef.close(this.selected);
    }
}
