import { Component, EventEmitter, Input, Output, Type, ViewContainerRef } from '@angular/core';
import { BaseComponent } from '@core/abstracts/base.component';
import { BurundiCountryId } from '@shared/repositories/geolocation.repository';
import { IdentityModel } from '@shared/repositories/identity.repository';
import { IdentityBurundiComponent } from '@shared/components/identity/burundi/identity-burundi.component';
import { IdentityKenyaComponent } from './kenya/identity-kenya.component';


@Component({
    selector: 'identity',
    templateUrl: './identity.component.html',
    standalone: false
})
export class IdentityComponent extends BaseComponent
{

    @Input()
    public countryId: string;

    @Output()
    public identityEvent = new EventEmitter<IdentityModel>();

    constructor(
        private viewContainerRef: ViewContainerRef)
    {
        super();
    }

    public async ngOnInit(): Promise<void>
    {
        super.ngOnInit();
        this.loadForm(this.countryId)
    }

    private loadForm(countryId: string)
    {
        const component: Type<IdentityKenyaComponent | IdentityBurundiComponent> = (countryId === BurundiCountryId)
            ? IdentityBurundiComponent
            : IdentityKenyaComponent;
        const componentRef = this.viewContainerRef.createComponent(component);
        componentRef.instance.identityEvent.subscribe((eventData: IdentityModel) =>
        {
            this.identityEvent.emit(eventData);
        });
    }
}
