import { Component, Input } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { MatDialogRef } from '@angular/material/dialog';

import { PaymentDialogComponent } from './dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PaymentComponentConfig } from './payment';
import { OrderRepository } from '@shared/repositories/order.repository';
import dayjs from 'dayjs';


interface FlexiInstalment{
    expiresAfter: string,
    initialPayment: number
}

interface FixedInstalment{
    interval: string,
    numInstalments: number,
    plan: any[]
}

@Component({
    selector: 'payment-mpesa-instalment',
    templateUrl: './mpesa-instalment.html',
    standalone: false
})
export class MpesaInstalmentPaymentComponent {
    @Input() config: PaymentComponentConfig;

    public isLoading = false;
    public form: FormGroup;
    public fixed: FixedInstalment;
    public flexi: FlexiInstalment;
    public paymentMethods = [
        {
            key: 1,
            val: 'mpesaDirect'
        },
        {
            key: 2,
            val: 'mpesaPaybill'
        }
    ];

    constructor(
        private snackbar: MatSnackBar,
        private dialog: MatDialogRef<PaymentDialogComponent>,
        private orderRepository: OrderRepository
    ) {}

    public ngOnInit() {

        this.form = new FormGroup({
            targetType: new FormControl(this.config.targetType, [Validators.required]),
            targetId: new FormControl(this.config.targetId, [Validators.required]),
            paymentMethod: new FormControl(null, [Validators.required]),
            phoneNumber: new FormControl(this.config.receiver?.phoneNumber, [Validators.required]),
            paymentId: new FormControl(this.config.provider.id, [Validators.required])
        });

        if (!this.config.provider) {
            this.dialog.close(false);
            return;
        }

        const props = JSON.parse(this.config.provider.properties);

        if (props.flexibleInstalment)
        {
            this.flexi = props.flexibleInstalment;
        }
        else if (props.fixedInstalment)
        {
            this.fixed = props.fixedInstalment;
            this.fixed.plan = this.generateInstalmentPlan();
        }
        else
        {
            // No instalment settings has been provided, so aborting
            console.error('No instalment settings has been provided');
            this.dialog.close('Ending');
        }
    }

    public getInitialPayment()
    {
        if (!this.flexi?.initialPayment) return 0;
        return Math.floor((this.config.amount * this.flexi.initialPayment) / 100) * 100;
    }

    private generateInstalmentPlan() {

        const totalAmount = this.config.amount;
        const numInstalments = this.fixed.numInstalments;
        const intervalAr = this.fixed.interval.split(' ');
        const intervalValue = parseInt(intervalAr[0]);
        const intervalType = intervalAr[1];

        const instalmentAmount = Math.ceil(totalAmount / numInstalments);
        const instalments = [];
        
        let remainingBalance = totalAmount;
        let date = dayjs();

        for (var i = 0; i < numInstalments - 1; i++) {
            var amount = Math.ceil(instalmentAmount / 100) * 100;
            instalments.push({
                date: date.format('DD MMM YYYY'),
                amount
            });

            date = date.add(intervalValue, intervalType as dayjs.ManipulateType);
            remainingBalance -= amount;
        }

        // Last installment should not exceed the remaining balance
        amount = Math.min(remainingBalance, Math.ceil(instalmentAmount / 100) * 100);
        instalments.push({
            date: date.format('DD MMM YYYY'),
            amount
        });

        return instalments;
    }

    public async submit() {

        if (this.form.invalid)
        {
            this.snackbar.open('requiredInputFieldsMustBeCompleted');
            return;
        }

        this.isLoading = true;

        await this.orderRepository.resendPaymentAsync(this.form.value);
        
        this.dialog.close(false);
    }
}