import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@core/guards/auth.guard';
import { WrapperComponent } from '@core/components/wrapper';
import { NotFoundComponent } from '@core/components/not-found';
import { DashboardComponent } from '@core/components/dashboard.component';


const routes: Routes = [
    {
        path: 'auth',
        loadChildren: () => import('./auth/auth.module').then(x => x.AuthModule)
    },
    {
        path: 'not-found',
        component: NotFoundComponent
    },
    {
        path: '',
        component: WrapperComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
            {
                path: 'dashboard',
                component: DashboardComponent,
            },
            {
                path: 'collective',
                loadChildren: () => import('./collective/collective.module').then(m => m.CollectiveModule),
            },
            {
                path: 'company',
                loadChildren: () => import('./company/company.module').then(m => m.CompanyModule),
            },
            {
                path: 'user',
                loadChildren: () => import('./user/user.module').then(m => m.UserModule),
            },
            {
                path: 'order',
                loadChildren: () => import('./order/order.module').then(m => m.OrderModule),
            },
            {
                path: 'underwriter',
                loadChildren: () => import('./underwriter/underwriter.module').then(m => m.UnderwriterModule),
            },
            {
                path: 'policy',
                loadChildren: () => import('./policy/policy.module').then(m => m.PolicyModule),
            },
            {
                path: 'claim',
                loadChildren: () => import('./claim/claim.module').then(m => m.ClaimModule),
            },
            {
                path: 'admin',
                loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
            },
            {
                path: 'organization',
                loadChildren: () => import('./organization/organization.module').then(m => m.OrganizationModule),
            },
            {
                path: 'distribution-channel',
                loadChildren: () => import('./distribution-channel/distribution-channel.module').then(m => m.DistributionChannelModule),
            },
            {
                path: 'transactions',
                loadChildren: () => import('./transaction/transaction.module').then(m => m.TransactionModule),
            },
            {
                path: 'invoices',
                loadChildren: () => import('./invoice/invoice.module').then(m => m.InvoiceModule),
            },
            {
                path: 'report',
                loadChildren: () => import('./report/report.module').then(m => m.ReportModule),
            },
            {
                path: '**',
                redirectTo: 'dashboard',
            },
        ]
    },
    {
        path: '**',
        redirectTo: '/not-found',
        pathMatch: 'full'
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutesModule { }
