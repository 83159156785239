import { Injectable } from '@angular/core';
import { CommandService, ICommandResponse } from '@core/services/command.service';
import { QueryService, IQueryResponse } from '@core/services/query.service';

export interface ProfilePolicyBundle
{
    orderId: string;
    orderLineId: string;
    organizationId: string;
    collectiveId: string;
    collectiveName: string;
    name: string;
    createdAt: string;
    validFrom: string;
    validTo: string;
    policies: ProfilePolicyModel[];
    isActive: boolean;
    inWaitingPeriod: boolean;
    isExpired: boolean;
    isClaimable: boolean;
    isRenewable: boolean;
}

export interface ProfilePolicyModel
{
    id: string;
    organizationId: string;
    collectiveId: string;
    collectiveName: string;
    orderId: string;
    orderLineId: string;
    policyNumber: string;
    name: string;
    productName: string;
    policyholderId: string;
    policyholderName: string;
    policyholderNationalId: string;
    beneficiaryId: string;
    beneficiaryName: string;
    beneficiaryNationalId: string;
    createdAt: string;
    validFrom: string;
    validTo: string;
    numClaims: number;
}



export interface CollectiveSummaryModel
{
    id: string;
    name: string;
    organizationName: string;
    isActive: boolean;
    totalPaid: number;
    totalPending: number;
}

export interface UserModel
{
    userId: string;
    email: string;
    nationalId: string;
    firstName: string;
    familyName: string;
    name: string;
    phoneNumber: string;
    createdAt: Date;
    collectiveId: string;
    collectiveName: string;
    collectiveStatus: number;
    memberSince: Date;
}

export interface UsersQueryModel
{
    users: UserModel[];
    totalUsers: number;
}


export interface User
{
    id: string;
    nationalId: string;
    name: string;
    createdAt: Date;
    phoneNumber: string;
    collectiveId: string;
    collectiveName: string;
    collectiveCode: string;
}

export interface UserSearchModel
{
    id: string;
    nationalId: string;
    name: string;
    createdAt: Date;
    phoneNumber: string;
}

export interface UserSearchQueryResponse
{

    users: UserSearchModel[]
}

export enum CheckIdentityStatus 
{
    NotFound = 0,
    FoundLocally = 1,
    FoundRemotely = 2,
    UnknownIdentityType = -1,
    PhoneInvalid = -2,
    PhoneNumberTaken = -3,
    UserRegisteredWithDifferentPhone = -4,
    TimeoutException = -5,
    Exception = -6
}

export enum IdTypes
{
    NationalId = 1,
    BirthCertificateNbr = 2
}

enum Gender 
{
    Undefined = 0,
    Male = 1,
    Female = 2
}

export interface CheckIdentityDetails 
{
    userId?: string;
    idNumber?: string;
    firstName?: string;
    familyName?: string;
    gender?: Gender;
    dateOfBirth?: string;
    name?: string;
    placeOfBirth?: string;
    placeOfLive?: string;
    citizenship?: string;
    genderText?: string;
}

export interface CheckIdentityResultModel
{
    idType: IdTypes;
    idNumber: string;
    status: CheckIdentityStatus;
    details: CheckIdentityDetails;
}

export interface CheckIdentityModel
{
    idType: number;
    idNumber: string;
    phone?: string;
    userId?: string;
}

export interface TrainerProfileQueryModel
{
    id: string;
    name: string;
    nationalId: string;
    phoneNumber: string;
    activePolicies: number;
    hasActivePolicies: boolean;
    hasPendingSales: boolean;
}

export interface TrainerProfileQueryResult
{
    profile: TrainerProfileQueryModel;
}

export enum PII
{
    PhoneNumber,
    NationalId
}

export interface UsersQueryPayload
{
    searchString?: string;
    skip?: number;
    take?: number;
}

export interface UsersQueryResponse
{
    users: UsersQueryUserModel[];
    totalUsers: number;
}

export interface UsersQueryUserModel
{
    id: string;
    name: string;
    phoneNumber: string;
    nationalId: string;
    isVerified: boolean;
}


export interface UserSearchQueryPayload
{
    searchString: string;
    skip?: number;
    take?: number;
}

export interface UserUpdatePasswordPayload
{
    nationalId: string;
    phone: string;
}

export interface UserTransferToCollective
{
    userId: string;
    collectiveId: string;
}

export interface UserProfileModel
{
    id: string;
    createdAt: string;
    familyName: string;
    firstName: string;
    gender: number;
    dateOfBirth: string;
    nationalId: string;
    phoneNumber: string;
    isVerified: boolean;
    isUserBlocked: boolean;
    age: number;
    name: string;
}

export interface UserProfileCollectiveModel
{
    id: string;
    organizationId: string;
    organizationName: string;
    distributionChannelName?: string;
    distributionChannelUserDataKeys?: string[];
    name: string;
    status: number;
    memberSince: string;
}


export interface UserProfileRoleModel
{
    id: string;
    createdAt: string;
    name: string;
    organizationName: string;
}

export interface UserProfileDistributionChannelModel
{
    id: string;
    organizationName: string;
    name: string;
    type: string;
    memberSince: Date;
}

export interface UserProfileQueryResponse
{
    profile: UserProfileModel;
    collective: UserProfileCollectiveModel;
    distributionChannels: UserProfileDistributionChannelModel[];
    roles: UserProfileRoleModel[];
    userData: any;
}

export interface UserOrderModel
{
    id: string;
    name: string;
    byId: string;
    byName: string;
    createdAt: string;
    orderLines: UserOrderLineModel[];
    allPaid: boolean;
    totalQuantity: number;
    totalPrice: number;
    totalCurrency: string;
}

export interface UserOrderLineModel
{
    id: string;
    organizationId: string;
    collectiveId: string;
    customerId: string;
    customerName: string;
    name: string;
    orderType: number;
    quantity: number;
    price: number;
    regularPrice: number;
    currency: string;
    paidAt: string;
    createdAt: string;
    orderId: string;
    orderById: string;
    orderByName: string;
    orderName: string;
    orderCreatedAt: string;
    isPaid: boolean;
    orderTypeText: string;
}

export interface UserOrdersQueryResponse
{
    orders: UserOrderModel[];
}


export interface UserPolicyModel
{
    id: string;
    productName: string;
    createdAt?: Date;
    validFrom?: Date;
    validTo?: Date;
    orderLineProductId: string;
    orderLineId: string;
    name: string;
    isForJunior: string;
    cancelledAt?: Date;
    policyholderName: string;
    policyholderNationalId: string;
    beneficiaryName?: string;
    beneficiaryNationalId?: string;
    dependantName?: string;
    dependantNationalId?: string;
    isActive?: boolean;
    inWaitingPeriod?: boolean;
}

export interface UserBundleModel
{
    orderLineid: string;
    createdAt: string;
    name: string;
    validFrom: string;
    validTo: string;
    policyNumber: string;
    policies: UserPolicyModel[];
}

export interface UserPoliciesQueryResponse
{
    bundles: UserBundleModel[];
}

export interface PhoneSavePayload
{
    userId: string;
    phoneNumber: string;
}

@Injectable({ providedIn: 'root' })
export class UserRepository
{
    constructor(
        private queryService: QueryService,
        private commandService: CommandService) { }


    public getUsers(data: UsersQueryPayload): Promise<IQueryResponse<UsersQueryResponse>>
    {
        return this.queryService.queryAsync<UsersQueryResponse>('UsersQuery', data);
    }

    public getUser(data: any = {}): Promise<IQueryResponse<UsersQueryModel>>
    {
        return this.queryService.queryAsync<UsersQueryModel>('UserQuery', data);
    }

    public getUserProfile(userId: string): Promise<IQueryResponse<UserProfileQueryResponse>> 
    {
        return this.queryService.queryAsync<UserProfileQueryResponse>('UserProfileQuery', { userId });
    }

    public getUserOrders(userId: string): Promise<IQueryResponse<UserOrdersQueryResponse>> 
    {
        return this.queryService.queryAsync<UserOrdersQueryResponse>('UserOrdersQuery', { userId });
    }

    public async getUserPolicies(userId: string): Promise<IQueryResponse<UserPoliciesQueryResponse>>
    {
        return this.queryService.queryAsync<UserPoliciesQueryResponse>('UserPoliciesQuery', { userId });
    }

    public getPII(userId: string, pii: PII): Promise<IQueryResponse<string>> 
    {
        return this.queryService.queryAsync('PIIQuery', { userId })
    }

    public searchUsers(data: UserSearchQueryPayload): Promise<IQueryResponse<UserSearchQueryResponse>>
    {
        return this.queryService.queryAsync<UserSearchQueryResponse>('UserSearchQuery', data);
    }

    public transferToCollective(data: UserTransferToCollective): Promise<ICommandResponse> 
    {
        return this.commandService.publishAsync('UserTransferToCollective', data);
    }

    public blockAsync(userId: any, blockUser: boolean): Promise<ICommandResponse> 
    {
        return this.commandService.publishAsync('BlockUser', { userId });
    }

    public setRole(data: { userId: string, roleId: string }): Promise<ICommandResponse> 
    {
        return this.commandService.publishAsync('UserSetRole', data);
    }

    public async getUsersByRoles(roleIds: string[]): Promise<any[]> 
    {
        const response = await this.queryService.queryAsync<any>('GetUsersByRole', { roleIds });
        return response.result.users || [];
    }

    public async checkIdentityAsync(data: CheckIdentityModel): Promise<IQueryResponse<CheckIdentityResultModel>>
    {
        return await this.queryService.queryAsync<any>('CheckIdentity', data);
    }

    public async getUsersByCollective(collectiveId: string): Promise<any[]> 
    {
        const response = await this.queryService.queryAsync<any>('GetUsersByCollective', { collectiveIds: [collectiveId] });
        return response.result.users || [];
    }

    public createUser(data: any): Promise<ICommandResponse> 
    {
        return this.commandService.publishAsync('UserCreate', data);
    }

    public changePassword(data: UserUpdatePasswordPayload): Promise<ICommandResponse> 
    {
        return this.commandService.publishAsync('UserUpdatePassword', data);
    }

    public IprsReconciliation(data: any): Promise<ICommandResponse>
    {
        return this.commandService.publishAsync('IprsReconciliation', data);
    }

    public saveProfile(data: any): Promise<ICommandResponse> 
    {
        return this.commandService.publishAsync('UserUpdate', data);
    }

    public savePhoneNumber(data: PhoneSavePayload): Promise<ICommandResponse> 
    {
        return this.commandService.publishAsync('PhoneSave', data);
    }
}
