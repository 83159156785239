import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { PageEvent } from '@angular/material/paginator';import { environment } from '@environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { AccessService } from '@core/services/access.service';

export interface UiSettings
{
    isDarkMode: boolean;
}

export interface ActionLink
{
    label: string;
    onClick: Function;
    icon: string;
}

export interface MenuLink
{
    title: string;
    icon: string;
    description: string;
    link: string[];
}

export interface UiState
{
    form?: any;
    pageEvent?: PageEvent;
}

@Injectable({ providedIn: 'root' })
export class UiService
{
    private _settings: BehaviorSubject<UiSettings> = null;
    private _menuLinks: MenuLink[] | undefined;
    private _actionLinks$ = new BehaviorSubject<ActionLink[]>([]);

    constructor(
        private accessService: AccessService,
        private cookieService: CookieService
    )
    {
        const defaults = {
            isDarkMode: false
        };

        const serializedSettings = localStorage.getItem('settings');
        const settings: UiSettings = serializedSettings
            ? JSON.parse(serializedSettings)
            : defaults;

        this._settings = new BehaviorSubject<UiSettings>(settings);
        this._settings.subscribe(settings =>
        {
            localStorage.setItem('settings', JSON.stringify(settings));
        });
    }

    public get settings(): Observable<UiSettings>
    {
        return this._settings.asObservable();
    }

    public get isDarkMode(): boolean
    {
        return this._settings.getValue().isDarkMode;
    }

    public set isDarkMode(value: boolean)
    {
        const settings = this._settings.getValue();
        settings.isDarkMode = value;
        this._settings.next(settings);
    }

    public get actionLinks$(): Observable<ActionLink[]>
    {
        return this._actionLinks$.asObservable();
    }

    public setLinks(links: ActionLink[])
    {
        this._actionLinks$.next(links);
    }

    public getUiState(name: string): UiState
    {
        const serState = localStorage.getItem(`${name}_state`);
        if (serState)
        {
            const state = JSON.parse(serState);
            return {
                form: state[0] || null,
                pageEvent: state[1] || null
            };
        }

        return null;
    }

    public setCookie(key: string, value: string | null)
    {

        if (value)
        {
            let expires = new Date();
            expires.setMinutes(expires.getMinutes() + 60);
            this.cookieService.set(key, value, expires, '/', environment.domain);
            return;
        }

        this.cookieService.delete(key);
    }

    public setUiState(name: string, form: any, pageEvent?: PageEvent): void
    {
        const data = [form, pageEvent];
        const serData = JSON.stringify(data);
        localStorage.setItem(`${name}_state`, serData);
    }

    public setNavigationState(name: string, state: boolean): void
    {
        const serData = JSON.stringify(state);
        localStorage.setItem(`${name}_state`, serData);
    }

    public clearUiState(name: string): void
    {
        localStorage.removeItem(`${name}_state`);
    }

    public getMenuLinks(): MenuLink[]
    {
        this._menuLinks = this.generateMenuLinks();
        return this._menuLinks;
    }

    private generateMenuLinks(): MenuLink[]
    {
        const result: MenuLink[] = [];

        if (this.accessService.hasAccess('collective'))
        {
            result.push({
                title: 'collectives',
                icon: 'account-multiple',
                link: ['/collective'],
                description: 'Search and manage collective data',
            });
        }

        if (this.accessService.hasAccess('user', 2))
        {
            result.push({
                title: 'users',
                icon: 'account-group',
                link: ['/user'],
                description: 'Search and manage information on registered users',
            });
        }

        if (this.accessService.hasAccess('organization'))
        {
            result.push({
                title: 'organizations',
                icon: 'domain',
                link: ['/organization'],
                description: 'Customize the fundamentals of current organization, such as roles and products',
            });
        }

        if (this.accessService.hasAccess('DistributionChannel'))
        {
            result.push({
                title: 'distributionChannels',
                icon: 'domain',
                link: ['/distribution-channel'],
                description: 'Search and manage information on the distribution channels',
            });
        }



        if (this.accessService.hasAccess('company'))
        {
            result.push({
                title: 'companies',
                icon: 'office-building',
                link: ['/company'],
                description: 'Search and manage collective data',
            });
        }

        if (this.accessService.hasAccess('Underwriter'))
        {
            result.push({
                title: 'underwriters',
                icon: 'file-document-edit-outline',
                link: ['/underwriter'],
                description: 'Search and manage information on underwriters',
            });
        }

        if (this.accessService.hasAccess('order', 2))
        {
            result.push({
                title: 'orders',
                icon: 'basket',
                link: ['/order'],
                description: 'Search and manage information on relevant orders and policies',
            });
        }

        if (this.accessService.hasAccess('policy'))
        {
            result.push({
                title: 'policies',
                icon: 'file-table-box-outline',
                link: ['/policy'],
                description: 'Search and manage information on relevant orders and policies',
            });
        }

        if (this.accessService.hasAccess('claim'))
        {
            result.push({
                title: 'claims',
                icon: 'playlist-star',
                link: ['/claim'],
                description: 'View and take action on submitted policy claims',
            });


        }

        if (this.accessService.hasAccess('TransactionQuery'))
        {
            result.push({
                title: 'transactions',
                icon: 'credit-card',
                link: ['/transactions'],
                description: 'View and search transactions',
            });
        }

        if (this.accessService.hasAccess('InvoiceQuery'))
        {
            result.push({
                title: 'invoices',
                icon: 'receipt',
                link: ['/invoices'],
                description: 'View and search invoices',
            });
        }


        if (this.accessService.hasAccess('ussd'))
        {
            result.push({
                title: 'admin',
                icon: 'tune-vertical',
                link: ['/admin'],
                description: 'Message, USSD',
            });
        }

        if (this.accessService.hasAccess('report'))
        {
            result.push({
                title: 'reports',
                icon: 'file-table-box-outline',
                link: ['/report'],
                description: 'View, export and print reports',
            });
        }

        return result;
    }

    public removeFilterSettingsFromLocalStorage()
    {

        const keys = Object.keys(localStorage);

        keys.forEach((key) =>
        {
            if (key.endsWith('_state'))
            {
                localStorage.removeItem(key);
            }
        });
    }
}
