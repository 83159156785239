import { NgModule } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatIconRegistry } from '@angular/material/icon';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AppComponent } from './app';
import { AppRoutesModule } from './app.routes';
import { getIcons } from './icons';
import { CoreModule } from '@core/core.module';
import { SharedModule } from '@shared/shared.module';
import { environment } from '@environments/environment';


@NgModule({
    imports: [
        BrowserAnimationsModule,
        CoreModule,
        SharedModule,
        AppRoutesModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    ],
    providers: [
    ],
    declarations: [
        AppComponent],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule
{
    constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer)
    {
        getIcons().forEach((icon) =>
        {
            if (!icon || icon.length <= 0)
            {
                return;
            }

            matIconRegistry.addSvgIcon(
                icon,
                domSanitizer.bypassSecurityTrustResourceUrl(`./assets/icons/svg/${icon}.svg`)
            );
        });
    }
}
