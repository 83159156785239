import { Component, Input } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'

import { PaymentDialogComponent } from './dialog';
import { MatDialogRef } from '@angular/material/dialog';

import { ClaimRepository } from '@shared/repositories/claim.repository';
import { PaymentComponentConfig } from './payment';

@Component({
    selector: 'payment-mpesa',
    templateUrl: './mpesa.html',
    standalone: false
})
export class MpesaPaymentComponent
{
    public form: FormGroup;
    @Input() config: PaymentComponentConfig;

    constructor(private dialog: MatDialogRef<PaymentDialogComponent>,
        private claimRepository: ClaimRepository)
    {
    }

    public ngOnInit()
    {
        this.form = new FormGroup({
            phoneNumber: new FormControl(this.config.receiver?.phoneNumber, [Validators.required]),
            paymentId: new FormControl(this.config.provider.id, [Validators.required]),
            amount: new FormControl(this.config.amount),
            currency: new FormControl(this.config.currency)
        });
    }

    public async submit() 
    {
        // claim payout
        if (this.config.queryConfig.idType === 'c') 
        {
            const response = await this.claimRepository.makePayout(this.config.targetId);
            this.dialog.close(response.isSuccess);
        }
        // payout distribution
    }
}