<mat-form-field class="tw-w-full">
  <mat-label class="tw-capitalize">{{ type }}</mat-label>
  <input type="text" placeholder="Search" aria-label="Search" matInput [formControl]="geolocationControl"
    [matAutocomplete]="auto">
  <mat-autocomplete #auto="matAutocomplete">
    <mat-option *ngFor="let geolocation of geolocations" [value]="geolocation.name" (click)="selectGeolocation(geolocation)">
      {{geolocation.name}}
    </mat-option>
  </mat-autocomplete>
  <mat-error>Not found</mat-error>
</mat-form-field>