import { Injectable } from '@angular/core';
import { CommandService, ICommandResponse } from '@core/services/command.service';
import { QueryService, IQueryResponse } from '@core/services/query.service';
import exp from 'constants';

export interface IDistributionChannel 
{
    id: string;
    name: string;
    countryId: string;
}

export interface DistributionChannelsQueryPayload
{
    searchString?: string;
    sortBy?: string;
    orderBy?: string;
    skip?: number;
    take?: number;
}

export interface DistributionChannelsQueryModel 
{
    id: string;
    createdAt: Date;
    name: string;
    organization: string;
    enabled: boolean;
    numTrainers: number;
    numManagers: number;
    numMembers: number;
    numCollectives: number;
}

export interface DistributionChannelsQueryResponse
{
    distributionChannels: DistributionChannelsQueryModel[];
    totalCount: number;
}

export interface DistributionChannelListModel 
{
    id: string;
    name: string;
    organization: string;
}

export interface DistributionChannelsListQueryResponse
{
    distributionChannels: DistributionChannelListModel[];
}

export interface DistributionChannelUser
{
    id: string
    organizationName: string
    name: string
    type: string
    memberSince: string
}

export interface DistributionChannelQueryResponse
{
    distributionChannel: DistributionChannelModel;
}

export interface DistributionChannelModel
{
    id: string;
    name: string;
    organizationId?: string;
    organization?: string;
    createdAt: Date;
    enabled: boolean;
    countryId?: string;
    country?: string;
    managers: ManagerModel[];
    trainers: TrainerModel[];
    payments: PaymentModel[];
    products: ProductModel[];
    payoutDistributions: PayoutDistributionModel[];
    numApprovedMembers: number;
}

export interface ManagerModel
{
    id: string;
    name: string;
    nationalId: string;
    phoneNumber: string;
    memberSince: Date;
    cancelledAt?: Date;
}

export interface TrainerModel
{
    id: string;
    name: string;
    nationalId: string;
    phoneNumber: string;
    memberSince: Date;
    cancelledAt?: Date;
    collectives: string;
    members: string;
}



export interface PayoutDistributionModel
{
    id: string;
    createdAt: Date;
    name: string;
    description: string;
    percentage: number;
}

export interface PaymentModel
{
    id: string;
    name: string;
    discriminator: string;
    direction: PaymentProviderDirection;
    directionString?: string;
    description?: string;
    minAmount: number;
    maxAmount?: number;
    currency: string;
    properties: any;
}

export interface ProductModel
{
    id?: string;
    name?: string;
    summary?: string;
    description?: string;
    underwriter?: string;
    productType?: string;
    currency?: string;
    addons?: string;
    price?: number;
    createdAt: Date;
}

export enum PaymentProviderDirection
{
    Inbound = 'Inbound',
    Outbound = 'Outbound',
}


export interface DistributionChannelCollectiveModel
{
    id: string;
    name: string;
    numMembers: number;
    numPolicies: number;
}

export interface DistributionChannelCollectivesQueryResponse
{
    collectives: DistributionChannelCollectiveModel[];
}

export interface DistributionChannelRemoveMemberPayload
{
    distributionChannelId: string;
    userId: string;
    type: string;
}

@Injectable({ providedIn: 'root' })
export class DistributionChannelRepository
{
    constructor(
        private commandService: CommandService,
        private queryService: QueryService)
    {
    }

    public async getDistributionChannels(data: DistributionChannelsQueryPayload): Promise<IQueryResponse<DistributionChannelsQueryResponse>>
    {
        return await this.queryService.queryAsync('DistributionChannelsQuery', data);
    }

    public async getDistributionChannel(distributionChannelId: string): Promise<IQueryResponse<DistributionChannelQueryResponse>>
    {
        return await this.queryService.queryAsync("DistributionChannelQuery", { distributionChannelId });
    }

    public async getDistributionChannelCollectives(distributionChannelId: string): Promise<IQueryResponse<DistributionChannelCollectivesQueryResponse>>
    {
        return await this.queryService.queryAsync<DistributionChannelCollectivesQueryResponse>('DistributionChannelCollectivesQuery', { distributionChannelId });
    }

    public async getDistributionChannelsList(): Promise<IQueryResponse<DistributionChannelsListQueryResponse>>
    {
        return this.queryService.queryAsync<DistributionChannelsListQueryResponse>('DistributionChannelsListQuery');
    }

    public create(data: { name: string }): Promise<ICommandResponse>
    {
        return this.commandService.publishAsync('DistributionChannelCreate', data);
    }

    public update(data: { name: string }): Promise<ICommandResponse>
    {
        return this.commandService.publishAsync('DistributionChannelUpdate', data);
    }

    public member(data: any): Promise<ICommandResponse>
    {
        return this.commandService.publishAsync('DistributionChannelSetMember', data);
    }

    public removeMember(data: DistributionChannelRemoveMemberPayload): Promise<ICommandResponse>
    {
        return this.commandService.publishAsync('DistributionChannelRemoveMember', data);
    }

    public payment(data: any): Promise<ICommandResponse>
    {
        return this.commandService.publishAsync('DistributionChannelSetPayment', data);
    }

    public removePayment(data: any): Promise<ICommandResponse>
    {
        return this.commandService.publishAsync('DistributionChannelRemovePayment', data);
    }

    public product(data: any): Promise<ICommandResponse>
    {
        return this.commandService.publishAsync('DistributionChannelSetProduct', data);
    }

    public removeProduct(data: any): Promise<ICommandResponse>
    {
        return this.commandService.publishAsync('DistributionChannelRemoveProduct', data);
    }
}
