<ng-container *ngIf="isReady; else loading">

    <div class="tw-flex tw-flex-row tw-justify-between tw-mt-3 tw-items-center">

        <div *ngIf="entries && entries.length > 0; else noEntries">

            <table>
                <tbody>
                    <tr *ngFor="let entry of entries; let i = index;">
                        <td>
                            <div class="space-x-6 tw-flex tw-flex-col tw-items-start tw-justify-start">
                                <strong>{{entry.title}}</strong>
                                <p>{{entry.selection}}</p>
                            </div>
                        </td>
                        <td class="tw-text-end tw-text-black ">
                            <button type="button" [disabled]="isEditable" (click)="editExistingSaleAsync(i)"
                                mat-icon-button class="tw-m-4">
                                <mat-icon svgIcon="pencil" aria-label="Edit" class="tw-w-6 tw-h-6"></mat-icon>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="tw-flex tw-flex-row tw-mt-4 tw-justify-items-start tw-space-x-6">

        <button type="button" class="tw-btn-error" (click)="cancelEditingSale()">
            Cancel
        </button>

        <div class="tw-text-center hover:tw-bg-red-100 tw-bg-red-50">
            <button type="button" class="tw-btn-error" (click)="removeExistingSaleAsync()">
                <mat-icon svgIcon="delete-sweep"></mat-icon>
                Remove
            </button>
        </div>

    </div>
</ng-container>

<ng-template #loading>
    <section class="tw-py-4 tw-m-8">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        <h3 style="text-align: center;margin-top: 15px">loading</h3>
    </section>
</ng-template>


<ng-template #noEntries>
    <h4>noEntriesToEdit</h4>
</ng-template>