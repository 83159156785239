import { Component, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TokenService } from '@core/services/token.service';
import { Dependant, SaleSummary } from '@shared/repositories/sale.repository';
import { ProductSelect } from './order-product.component';

export interface UserSelect
{
    id: string;
    name: string;
    nationalId?: string;
    phoneNumber?: string;
}

export interface OrderData
{
    collective: { id: string, countryCode?: string, countryId?: string },
    policyholder?: { id: string, name: string, idNumber: string },
    editOrder?: boolean;
    sale?: SaleSummary;
    productId?: string;
}

@Component({
    templateUrl: 'order.component.html',
    standalone: false
})
export class OrderComponent
{
    public form: UntypedFormGroup = null;
    public collective: { id: string, countryCode?: string, countryId?: string } = null;
    public policyholder: UserSelect;
    public product: ProductSelect;
    public productId: string;
    public dependants: Dependant[];
    public isLoading = false;
    public message: string = null;
    private _step: number = 0;
    private _edit: boolean = false;
    private _remove: boolean = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: OrderData,
        private dialogRef: MatDialogRef<OrderComponent>,
        private tokenService: TokenService,
    )
    {

        if (data.editOrder) 
        {
            this.setStep(1);
        }

        this.form = new UntypedFormGroup({
            beneficiaryId: new UntypedFormControl(null),
            dependants: new UntypedFormControl(null, []),
            policyholderId: new UntypedFormControl(null, [Validators.required]),
            policyholderName: new UntypedFormControl(null, [Validators.required]),
            orderType: new UntypedFormControl(null, [Validators.required]),
            productType: new UntypedFormControl(null, [Validators.required]),
            productId: new UntypedFormControl(null, [Validators.required]),
            productName: new UntypedFormControl(null, [Validators.required]),
            productPrice: new UntypedFormControl(null, [Validators.required]),
            productCurrency: new UntypedFormControl(null, [Validators.required]),
            userId: new UntypedFormControl(null, [Validators.required])
        });

        this.onExistingData(this.data);
        this.dialogRef.disableClose = true;
    }


    public get isForSpouse(): boolean 
    {
        return this.product && this.product.productType === 'spouse_bundle';
    }

    public get isForJunior(): boolean 
    {
        return this.product && (this.product.productType === 'junior_bundle' || this.product.productType === 'junior_life_cash');
    }

    public get subtitle(): string
    {
        if (this.orderType === 1)
        {
            switch (this.currentStep)
            {
                case 0:
                    return 'Pending sales';
                case 1:
                    return 'Sale';
                case 2:
                    return `Select product : ${this.policyholder.name.split(' ')[0]}`;
                case 3:
                    return this.isForJunior
                        ? 'junior'
                        : this.isForSpouse
                            ? 'spouse'
                            : 'beneficiary';
                case 4:
                    return 'Sale summary';
                default:
                    return ``;
            }
        }
        else 
        {
            switch (this.currentStep) 
            {
                case 0:
                    return 'Pending sales';
                case 1:
                    return 'Edit record';
                case 2:
                    return 'Select product';
                case 3:
                    return 'Sale summary';
                default:
                    return ``;
            }
        }
    }

    public get collectiveId(): string | null 
    {
        return this.collective && this.collective.id;
    }

    public get userId(): string 
    {
        return this.form.get('userId').value;
    }

    public get orderId(): string | null 
    {
        return this.form.get('orderId').value;
    }

    public get orderType(): number | null 
    {
        const result = this.form.get('orderType').value;
        return (result && parseInt(result)) || null;
    }

    public get properties(): { [key: string]: string } 
    {
        return this.form.get('properties').value;
    }

    public get currentStep(): number
    {
        return this._step;
    }

    public setRemove(remove: boolean): void
    {
        this._remove = remove;
    }

    public setStep(step: number): void
    {
        this._step = step;
    }

    public next(): void
    {
        this._step++;
    }

    public previous(): void
    {
        this._step--;
    }

    public get edit(): boolean
    {
        return this._edit;
    }

    public onExistingData(data: OrderData) 
    {
        if (data.collective)
        {
            this.setCollective(data.collective);
        }

        this.productId = data.productId;

        if (data.editOrder) 
        {
            this._edit = data.editOrder;

            if (data.sale)
            {
                const userSelect: UserSelect = {
                    id: data.sale.policyholderId,
                    name: data.sale.policyholderName
                }
                this.onUserSelect(userSelect);

                const productSelect: ProductSelect = {
                    orderType: data.sale.orderType,
                    productType: data.sale.productType,
                    productId: data.sale.productId,
                    productName: data.sale.productName,
                    productPrice: data.sale.productPrice,
                    productCurrency: data.sale.productCurrency
                }
                this.onProductSelect(productSelect);

                this.onDependantsSubmit(data.sale.dependants);

            }
        }
        else
        {
            // skip edit existing
            this.next();
            if (data.policyholder)
            {
                const userSelect: UserSelect = {
                    id: data.policyholder.id,
                    name: data.policyholder.name,
                    nationalId: data.policyholder.idNumber
                }

                this.onUserSelect(userSelect);
                this.next();
            }

        }

    }

    public onExistingEdited(): void
    {

        if (this._remove) 
        {
            this.onConfirmSubmit();
        }
        else if (this._edit)
        {
            this.next();

        }
    }

    private setCollective(collective: { id: string }): void 
    {
        this.collective = collective;
    }

    public onUserSelect(userSelect: UserSelect): void 
    {
        this.form.get('policyholderId').setValue(userSelect.id);
        this.form.get('policyholderName').setValue(userSelect.name);
        this.policyholder = userSelect;
    }

    public onBeneficiarySelect(beneficiaryId: string): void 
    {
        this.form.get('beneficiaryId').setValue(beneficiaryId);
    }

    public onProductSelect(productSelect: ProductSelect): void
    {
        this.form.get('orderType').setValue(productSelect.orderType);
        this.form.get('productType').setValue(productSelect.productType);
        this.form.get('productId').setValue(productSelect.productId || null);
        this.form.get('productName').setValue(productSelect.productName);
        this.form.get('productPrice').setValue(productSelect.productPrice);
        this.form.get('productCurrency').setValue(productSelect.productCurrency);
        this.product = productSelect;
    }

    public onDependantsSubmit(dependants: Dependant[]): void 
    {
        this.form.get('dependants').setValue(dependants);
        this.dependants = [...dependants];
    }

    public onConfirmSubmit(): void 
    {
        const userId = this.tokenService.getUserId();
        this.form.get('userId').setValue(userId);
        this.submitAsync();
    }

    // remove sale if beneficiary details is missing
    private imputeBeneficiary(): void 
    {
        if (!this.form.get('beneficiaryId').value)
            this.form.get('beneficiaryId').setValue(this.policyholder.id);
    }

    public async submitAsync(): Promise<void>
    {
        this.message = null;

        if (this._remove) this.imputeBeneficiary();

        if (!this.form.valid)
        {
            console.warn('invalid form');
            return;
        }

        this.isLoading = true;

        const data = this.form.value;
        if (this._remove) data.remove = true;
        this.dialogRef.close(data);
    }

    public async closeAsync(): Promise<void>
    {
        this.dialogRef.close();
    }
}