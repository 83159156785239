<mat-sidenav-container class="layout-fill">

    <mat-sidenav #sidenav style="min-width: 320px; max-width: 90%;" [opened]="isOpen" [fixedInViewport]="true"
        [mode]="mode" [ngClass]="sidenavClass" [position]="'start'" [disableClose]="!isMobile">

        <ng-container *ngIf="isReady">
            <ddf-menu [sidenav]="sidenav" [isMobile]="isMobile" [isOpen]="isOpen"></ddf-menu>
        </ng-container>

    </mat-sidenav>

    <mat-sidenav-content>
        <ng-container *ngIf="isReady">
            <div class="tw-flex tw-flex-row tw-justify-between tw-items-center tw-px-2 tw-bg-white">

                <button mat-button (click)="toggleSidenav()">
                    <mat-icon class="tw-text-brand-default" aria-label="Menu">menu</mat-icon>
                </button>
                
                <a routerLink="/">
                    <img src="assets/ddfinance-logo.png" alt="DDFinance" class="tw-max-h-[24px] tw-px-8" />
                </a>

            </div>

            <router-outlet></router-outlet>

        </ng-container>
    </mat-sidenav-content>

</mat-sidenav-container>