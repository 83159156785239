import { Component, Input } from '@angular/core';

@Component({
    selector: 'ddf-loader',
    templateUrl: './ddf-loader.component.html',
    standalone: false
})
export class LoaderComponent
{
    @Input() public message: string | undefined;
}
