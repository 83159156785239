import { Injectable } from '@angular/core';
import { CommandService, ICommandResponse } from '@core/services/command.service';



@Injectable({ providedIn: 'root' })
export class AccessRepository
{
    constructor(private commandService: CommandService) { }

    public accessAdd(data: any): Promise<ICommandResponse>
    {
        return this.commandService.publishAsync('AccessAdd', data);
    }
}
