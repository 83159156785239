import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatStepperModule } from '@angular/material/stepper';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSelectModule } from '@angular/material/select';
import { MatListModule } from '@angular/material/list';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSortModule } from '@angular/material/sort';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSliderModule } from '@angular/material/slider';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatNativeDateModule } from '@angular/material/core';
import { MatOptionModule } from '@angular/material/core';
import { EmptyListComponent } from '@shared/components/empty-list/empty-list.component';
import { TemplateComponent } from '@shared/components/ddf-template/ddf-template.component';
import { StateComponent } from '@shared/components/ddf-state/ddf-state.component';
import { LoaderComponent } from '@shared/components/ddf-loader/ddf-loader.component';
import { ActiveInactivePipe } from '@shared/pipes/active-inactive.pipe';
import { DefaultCurrencyPipe } from '@shared/pipes/default-currency.pipe';
import { DefaultDatePipe } from '@shared/pipes/default-date.pipe';
import { InitialsPipe } from '@shared/pipes/initials.pipe';
import { SearchPipe } from '@shared/pipes/search.pipe';
import { SafePipe } from '@shared/pipes/safe.pipe';
import { ClaimStatusPipe } from '@shared/pipes/claim-status.pipe';
import { ClaimStatusColorPipe } from '@shared/pipes/claim-status-color.pipe';
import { SkeletonComponent } from '@shared/components/skeleton/skeleton.component';
import { SearchUserComponent } from '@shared/components/search-user/search-user.component';
import { SearchUserDialog } from '@shared/components/search-user/search-user.dialog';
import { AuthRepository } from '@shared/repositories/auth.repository';
import { ClaimRepository } from '@shared/repositories/claim.repository';
import { CollectiveRepository } from '@shared/repositories/collective.repository';
import { CollectivesPayoutRepository } from '@shared/repositories/collectivespayout.repository';
import { CompanyRepository } from '@shared/repositories/company.repository';
import { DistributionChannelRepository } from '@shared/repositories/distribution-channel.repository';
import { DistributionPayoutRepository } from '@shared/repositories/distribution-payout.repository';
import { GeolocationRepository } from '@shared/repositories/geolocation.repository';
import { GroupRepository } from '@shared/repositories/group.repository';
import { HospitalRepository } from '@shared/repositories/hospital.repository';
import { IdentityRepository } from '@shared/repositories/identity.repository';
import { IllnessRepository } from '@shared/repositories/illness.repository';
import { MessageRepository } from '@shared/repositories/message.repository';
import { OrderRepository } from '@shared/repositories/order.repository';
import { OrganizationRepository } from '@shared/repositories/organization.repository';
import { PaymentRepository } from '@shared/repositories/payment.repository';
import { PolicyRepository } from '@shared/repositories/policy.repository';
import { ProductRepository } from '@shared/repositories/product.repository';
import { ReportRepository } from '@shared/repositories/report.repository';
import { RoleRepository } from '@shared/repositories/role.repository';
import { SaleRepository } from '@shared/repositories/sale.repository';
import { TrainerRepository } from '@shared/repositories/trainer.repository';
import { TransactionRepository } from '@shared/repositories/transaction.repository';
import { UnderwriterRepository } from '@shared/repositories/underwriter.repository';
import { FileRepository } from '@shared/repositories/file.repository';
import { UserRepository } from '@shared/repositories/user.repository';
import { ConfirmDialogComponent } from '@shared/components/confirm-dialog/confirm-dialog.component';
import { ListSelectorComponent } from '@shared/components/ddf-list-selector/ddf-list-selector.component';
import { ListSelectorDialog } from '@shared/components/select-items/select-items.dialog';
import { IdentityComponent } from '@shared/components/identity/identity.component';
import { IdentityDialog } from '@shared/components/identity/identity.dialog.component';
import { IdentityKenyaComponent } from '@shared/components/identity/kenya/identity-kenya.component';
import { IdentityKenyaIprsComponent } from '@shared/components/identity/kenya/identity-kenya-iprs.component';
import { IdentityKenyaNoIprsComponent } from '@shared/components/identity/kenya/identity-kenya-noiprs.component';
import { IdentityBurundiComponent } from '@shared/components/identity/burundi/identity-burundi.component';
import { IdentityKenyaJuniorComponent } from '@shared/components/identity/kenya/identity-kenya-junior.component';
import { OrderComponent } from '@shared/components/order/order.component';
import { OrderConfirmComponent } from '@shared/components/order/order-confirm.component';
import { OrderDependantComponent } from '@shared/components/order/order-dependant.component';
import { OrderEditComponent } from '@shared/components/order/order-edit.component';
import { OrderProductComponent } from '@shared/components/order/order-product.component';
import { OrderNewDependantComponent } from '@shared/components/order/order-new-dependant.component';
import { PaymentComponent } from '@shared/components/payment/payment';
import { PaymentDialogComponent } from '@shared/components/payment/dialog';
import { BankPaymentComponent } from '@shared/components/payment/bank';
import { ManualPaymentComponent } from '@shared/components/payment/manual';
import { MpesaPaymentComponent } from '@shared/components/payment/mpesa';
import { MpesaInstalmentPaymentComponent } from '@shared/components/payment/mpesa-instalment';
import { MY_DATE_FORMATS, DayjsDateAdapter } from '@shared/dayjs-adapter';
import { DateService } from '@shared/services/date.service';
import { ExcelService } from '@shared/services/excel.service';
import { FileListingService } from '@shared/services/file-listing.service';
import { FileUploadService } from '@shared/services/file-upload.service';
import { GeolocationComponent } from '@shared/components/geolocation/geolocation.component';
import { AccessRepository } from './repositories/access.repository';


@NgModule({
    declarations: [
        ConfirmDialogComponent,
        TemplateComponent,
        StateComponent,
        LoaderComponent,
        ActiveInactivePipe,
        ClaimStatusPipe,
        ClaimStatusColorPipe,
        DefaultCurrencyPipe,
        DefaultDatePipe,
        EmptyListComponent,
        InitialsPipe,
        ListSelectorComponent,
        ListSelectorDialog,
        SearchPipe,
        SafePipe,
        SearchUserComponent,
        SearchUserDialog,
        SkeletonComponent,
        IdentityDialog,
        IdentityComponent,
        IdentityKenyaComponent,
        IdentityKenyaIprsComponent,
        IdentityKenyaJuniorComponent,
        IdentityKenyaNoIprsComponent,
        IdentityBurundiComponent,
        OrderComponent,
        OrderConfirmComponent,
        OrderDependantComponent,
        OrderEditComponent,
        OrderProductComponent,
        OrderNewDependantComponent,
        PaymentComponent,
        PaymentDialogComponent,
        BankPaymentComponent,
        ManualPaymentComponent,
        MpesaPaymentComponent,
        MpesaInstalmentPaymentComponent,
        GeolocationComponent,
    ],
    exports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        CdkAccordionModule,
        MatDatepickerModule,
        MatStepperModule,
        MatExpansionModule,
        MatMenuModule,
        MatToolbarModule,
        MatNativeDateModule,
        MatDatepickerModule,
        MatDialogModule,
        MatSlideToggleModule,
        MatChipsModule,
        MatSidenavModule,
        MatTabsModule,
        MatListModule,
        MatSelectModule,
        MatOptionModule,
        MatDividerModule,
        MatCheckboxModule,
        MatRadioModule,
        MatTooltipModule,
        MatIconModule,
        MatProgressBarModule,
        MatSnackBarModule,
        MatSliderModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatGridListModule,
        MatDialogModule,
        MatSortModule,
        MatProgressBarModule,
        MatPaginatorModule,
        MatAutocompleteModule,
        ConfirmDialogComponent,
        ListSelectorComponent,
        ListSelectorDialog,
        TemplateComponent,
        EmptyListComponent,
        StateComponent,
        LoaderComponent,
        ActiveInactivePipe,
        ClaimStatusPipe,
        ClaimStatusColorPipe,
        DefaultCurrencyPipe,
        DefaultDatePipe,
        InitialsPipe,
        SafePipe,
        SearchPipe,
        SearchUserComponent,
        SearchUserDialog,
        IdentityDialog,
        IdentityComponent,
        IdentityKenyaComponent,
        IdentityKenyaIprsComponent,
        IdentityKenyaJuniorComponent,
        IdentityKenyaNoIprsComponent,
        IdentityBurundiComponent,
        OrderComponent,
        OrderConfirmComponent,
        OrderDependantComponent,
        OrderEditComponent,
        OrderProductComponent,
        OrderNewDependantComponent,
        GeolocationComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        CdkAccordionModule,
        MatDatepickerModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        FormsModule,
        ReactiveFormsModule,
        MatStepperModule,
        MatMenuModule,
        MatIconModule,
        MatButtonModule,
        MatTabsModule,
        MatProgressBarModule,
        MatTooltipModule,
        MatSortModule,
        MatOptionModule,
        MatCheckboxModule,
        MatChipsModule,
        MatAutocompleteModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        MatRadioModule,
    ],
    providers: [
        { provide: DateAdapter, useClass: DayjsDateAdapter.bind(this), deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
        provideHttpClient(withInterceptorsFromDi()),
        DateService,
        ExcelService,
        FileListingService,
        FileUploadService,
        AccessRepository,
        AuthRepository,
        ClaimRepository,
        CollectiveRepository,
        CollectivesPayoutRepository,
        CompanyRepository,
        DistributionChannelRepository,
        DistributionPayoutRepository,
        FileRepository,
        GeolocationRepository,
        GroupRepository,
        HospitalRepository,
        IdentityRepository,
        IllnessRepository,
        MessageRepository,
        PaymentRepository,
        PolicyRepository,
        ProductRepository,
        OrderRepository,
        OrganizationRepository,
        PolicyRepository,
        ProductRepository,
        ReportRepository,
        RoleRepository,
        SaleRepository,
        TrainerRepository,
        TransactionRepository,
        UnderwriterRepository,
        UserRepository,
    ]
})
export class SharedModule { }
