import { Component, Output } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { BaseComponent } from '@core/abstracts/base.component';
import { DateService } from "@shared/services/date.service";
import { UserRepository, CheckIdentityModel } from "@shared/repositories/user.repository";
import { IdentityModel } from '@shared/repositories/identity.repository';
import { Subject } from "rxjs";



@Component({
    selector: 'identity-kenya-junior',
    templateUrl: 'identity-kenya-junior.component.html',
    standalone: false
})
export class IdentityKenyaJuniorComponent extends BaseComponent
{
    public minDate: Date;
    public maxDate: Date;
    public form: UntypedFormGroup;
    public message: string | null = null;
    public skipIprs: boolean = false;
    public isChecking: boolean = false;

    @Output() public onSelected = new Subject<IdentityModel>();

    constructor(

        private dateService: DateService,
        private userRepository: UserRepository)
    {
        super();

        this.minDate = this.dateService.dayjs.utc().subtract(24, 'year').toDate();
        this.maxDate = this.dateService.dayjs.utc().subtract(1, 'month').toDate();

        const pattern = /^[0-9]{4,10}$/;

        this.form = new UntypedFormGroup({
            idNumber: new UntypedFormControl(null, [Validators.required, Validators.pattern(pattern)]),
            name: new UntypedFormControl(null),
            dateOfBirth: new UntypedFormControl(null),
            skipIprs: new UntypedFormControl(false)
        });
    }

    private setupSkipIprs(): void
    {
        this.skipIprs = true;

        const nameControl = this.form.get('name');
        nameControl.addValidators([Validators.required]);
        nameControl.markAsPristine();
        nameControl.updateValueAndValidity();

        const dobControl = this.form.get('dateOfBirth');
        dobControl.addValidators([Validators.required]);
        dobControl.updateValueAndValidity();
        dobControl.markAsPristine();
    }

    public async submitAsync(ev: Event): Promise<void>
    {
        ev.preventDefault();
        this.isChecking = true;

        if (this.form.invalid) return;

        this.message = null;

        const idNumber = this.form.value['idNumber'].replace(/^0+/, '');

        const data: CheckIdentityModel =
        {
            idType: 2,
            idNumber: idNumber,
        };

        const response = await this.userRepository.checkIdentityAsync(data);
        const idCheck = response.result;
        switch (idCheck.status)
        {
            case -6: // Exception
                this.message = 'An unknown error occurred. Try again or contact support';
                break;
            case -5: // TimeoutException
                this.message = 'The request to national registry timed out. Try again or contact support';
                break;
            case 0: // NotFound
                if (this.skipIprs)
                {
                    this.onSelected.next({
                        idNumber: this.form.value['idNumber'],
                        name: this.form.value['name'],
                        dateOfBirth: this.form.value['dateOfBirth'],
                        skipIprs: this.skipIprs
                    })
                }
                else
                {
                    this.setupSkipIprs();
                }
                break;
            case 2: // FoundRemotely
                this.onSelected.next({
                    idNumber: idCheck.idNumber,
                    name: idCheck.details.name,
                    dateOfBirth: new Date(idCheck.details.dateOfBirth),
                    skipIprs: this.skipIprs
                });
                break;
            default:
                this.message = 'The identity was not found';
                break;
        }

        this.isChecking = false;
    }
}
