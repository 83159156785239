import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { HttpClientInterceptor } from '@core/interceptors/http.interceptor';
import { NotFoundComponent } from '@core/components/not-found';
import { WrapperComponent } from '@core/components/wrapper';
import { MenuComponent } from '@core/components/menu';
import { DashboardComponent } from '@core/components/dashboard.component';
import { AccessService } from '@core/services/access.service';
import { AuthService } from '@core/services/auth.service';
import { BreakpointService } from '@core/providers/breakpoint.service';
import { CommandService } from '@core/services/command.service';
import { QueryService } from '@core/services/query.service';
import { TokenService } from '@core/services/token.service';
import { LogService } from '@core/providers/log.service';
import { PwaService } from '@core/providers/pwa.service';
import { UiService } from '@core/providers/ui.service';
import { CookieService } from "ngx-cookie-service";
import { SharedModule } from '@shared/shared.module';


@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        RouterModule,
    ],
    providers: [
        LogService,
        PwaService,
        UiService,
        AuthService,
        AccessService,
        BreakpointService,
        CommandService,
        QueryService,
        TokenService,
        CookieService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpClientInterceptor,
            multi: true,
            deps: [TokenService, AuthService]
        },
    ],
    declarations: [
        WrapperComponent,
        MenuComponent,
        NotFoundComponent,
        DashboardComponent,
    ],
    exports: [
        WrapperComponent,
        MenuComponent,
        DashboardComponent,
    ]
})
export class CoreModule { }
