<mat-nav-list>
    @for (menuLink of menuLinks; track menuLink) {
    <a mat-list-item [routerLink]="menuLink.link" routerLinkActive="is-active"
        (click)="selectMenu($event, menuLink.link)" data-test="simple">
        <div class="tw-flex tw-flex-row tw-items-center tw-space-x-4 tw-text-brand-default">
            <mat-icon [svgIcon]="menuLink.icon"></mat-icon>
            <span class="tw-uppercase" [attr.data-test]="menuLink.title">{{ menuLink.title }}</span>
        </div>
    </a>
    }
</mat-nav-list>

<mat-divider></mat-divider>


<button class="tw-m-2" type="button" mat-fab extended aria-label="Log Out" (click)="signoutAsync()">
    <mat-icon svgIcon="login"></mat-icon>
    Logout of DDFinance
</button>