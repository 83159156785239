import { Component, ViewChild } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { UiService } from '@core/providers/ui.service';
import { filter, takeUntil } from 'rxjs/operators';
import { BaseComponent } from '@core/abstracts/base.component';
import { AccessService } from '@core/services/access.service';
import { BreakpointService } from '@core/providers/breakpoint.service';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
    templateUrl: './wrapper.html',
    standalone: false
})
export class WrapperComponent extends BaseComponent
{
    public isReady = false;
    public currentBreakpoint: string;
    private _isOpen = false;

    @ViewChild('sidenav') sidenav!: MatSidenav;

    constructor(
        private uiService: UiService,
        private router: Router,
        private accessService: AccessService,
        private breakpointService: BreakpointService
    )
    {
        super();

        this.router.events.pipe(
            filter(x => x instanceof NavigationStart),
            takeUntil(this.ngSubscribe$)
        ).subscribe(() =>
        {
            this.uiService.setLinks([]);
        });

        this.breakpointService.current$.pipe(
            takeUntil(this.ngSubscribe$)
        )
            .subscribe(current =>
            {
                this.currentBreakpoint = `is-${current}`;
            });
    }

    public get sidenavClass(): string[]
    {
        return [this.currentBreakpoint];
    }

    public get isOpen(): boolean
    {
        return !this.isMobile || this._isOpen;
    }

    public get mode(): 'over' | 'side'
    {
        return this.isMobile ? 'over' : 'side';
    }

    public get isMobile(): boolean
    {
        return this.currentBreakpoint === 'is-xs' || this.currentBreakpoint === 'is-sm';
    }

    public toggleSidenav(): void
    {
        this.sidenav.toggle();
        this.uiService.setNavigationState('sidenav', this.sidenav.opened);
    }

    public async ngOnInit(): Promise<void>
    {
        super.ngOnInit();
        await this.accessService.loadAccessAsync();
        this.isReady = true;
    }
}
