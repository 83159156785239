import { Injectable } from '@angular/core';
import { CommandService, ICommandResponse } from '@core/services/command.service';
import { QueryService, IQueryResponse } from '@core/services/query.service';

export enum CollectivesFilter
{
    Inactive = -1,
    Renewable = 0,
    Active = 1,
    Dissolved = 100
}

export interface IOrganizationModel
{
    id: string;
    name: string;
}

export interface ITrainer
{
    id: string;
    name: string;
    nationalId?: any;
}

export interface ICollective
{
    id: string;
    name: string;
    code: string;
    numMembers: number;
    numPolicies: number;
    numRenewals: number;
    isActive: boolean;
    dissolvedAt: Date;
}

export interface CollectiveModel 
{
    id: string;
    trainerId: string;
    trainerName: string;
    organizationId: string;
    organizationName: string;
    distributionChannelId: string;
    distributionChannelName: string;
    name: string;
    code: string;
    locationId?: any;
    countryId?: string;
    countryCode?: string;
    chairPersonId?: any;
    chairPersonName?: any;
    secretaryId?: any;
    secretaryName?: any;
    treasurerId?: any;
    treasurerName?: any;
    createdAt: Date;
    firstSaleAt: Date;
    renewalDate: Date;
    county?: any;
    location?: any;
    yearOfCreation: number;
    frequency?: any;
    memberLimit: number;
    numMaleMembers: number;
    numFemaleMembers: number;
    locationName: string;
    locationLat?: number;
    locationLong?: number;
    numSales: number;
    isActive: boolean;
    isRenewable: boolean;
    isDefaultLevelSet: boolean;
    defaultProductId: string;
}

export interface TrainersCollectiveModel
{
    id: string;
    name: string;
    code: string;
    locationId?: any;
    latitude?: number;
    longitude?: number;
    chairPersonName?: any;
    secretaryName?: any;
    treasurerName?: any;
    createdAt: Date;
    renewalDate: Date;
    numSales: number;
    isActive: boolean;
    isRenewable: boolean;
    isDefaultLevelSet: boolean;
    defaultProductId: string;
    numMembers: number;
    numPolicies: number;
    numRenewals: number;
}


export interface OrderLine
{
    id: string;
    orderType: number;
    organizationId: string;
    collectiveId: string;
    customerId: string;
    customerName: string;
    customerNationalId: string;
    customerPhone: string;
    name: string;
    quantity: number;
    price: number;
    regularPrice: number;
    currency: string;
    paidAt?: any;
    createdAt: Date;
    orderId: string;
    orderById: string;
    orderByName: string;
    orderName: string;
    orderCreatedAt: Date;
    isPaid: boolean;
    orderTypeText: string;
}

export interface MemberModel
{
    id: string;
    name: string;
    nationalId: string;
    phoneNumber: string;
    hasActivePolicies: boolean;
    hasPendingSales: boolean;
    isVerified: boolean;
    productId?: string;
}

export interface GroupedOrderModel
{
    id: string;
    createdAt: Date;
    name: string;
    customerId?: string;
    customerName?: string;
    customerNationalId?: string;
    status: number;
    totalAmount: number;
    pendingAmount: number;
    currency: string;
}


export interface StatsByDate
{
    description: string;
    members: number;
    orders: number;
    purchases: number;
}


export interface CollectivesQueryPayload
{
    searchString: string;
    distributionChannelIds?: string[],
    skip?: number;
    take?: number;
}

export interface TrainersCollectivesQuery
{
    searchString: string;
}



export interface ICollective
{
    id: string;
    name: string;
    createdAt: Date;
    distributionChannel: string;
    trainerId: string;
    trainerName: string;
    dissolvedAt: Date;
    isActive: boolean;
    numMembers: number;
    numActivePolicies: number;
}

export interface CollectivesQueryResponse
{
    collectives: ICollective[];
    totalCount: number;
}

export interface TrainersCollectivesQueryResult
{
    collectives: TrainersCollective[];
}

export interface TrainersCollective
{
    id: string;
    name: string;
    code: string;
    county: string;
    isActive: boolean;
}

export interface PolicyModel 
{
    id: string;
    organizationId: string;
    collectiveId: string;
    collectiveName: string;
    orderLineId: string;
    policyNumber: string;
    name: string;
    productName: string;
    policyholderId: string;
    policyholderName: string;
    policyholderNationalId: string;
    beneficiaryId: string;
    beneficiaryName: string;
    beneficiaryNationalId: string;
    dependantName: string;
    createdAt: Date;
    validFrom: Date;
    validTo: Date;
    cancelledAt?: Date;
    isActive: boolean;
    inWaitingPeriod: boolean;
}

export interface BundleModel 
{
    orderLineId: string;
    orderName: string;
    collectiveName: string;
    policyholderId: string;
    policyholderName: string;
    policyholderNationalId: string;
    isActive: boolean;
    validFrom: Date;
    validTo: Date;
    policies: PolicyModel[];
    inWaitingPeriod: boolean;
}


export interface CollectivePayoutModel
{
    id: string;
    paidAt: Date;
    receiverNumber: string;
    amount: number;
}

export interface CollectiveQueryModel 
{
    collective: CollectiveModel;
    members: MemberModel[];
    orders: GroupedOrderModel[];
    policies: BundleModel[];
    statsByDate: StatsByDate[];
    payouts: CollectivePayoutModel[];
}


export interface TrainersCollectiveQueryResult
{
    collective: TrainersCollectiveModel;
    members: MemberModel[];
}

export interface TrainersPolicyModel 
{
    id: string;
    productName: string;
    policyholderId: string;
    coveredName: string;
    isActive: boolean;
}


export interface CollectiveSalesModel
{
    collectiveId: string;
    productId: string;
    userId?: string;
}

export interface CollectiveEditSalesDetailsType
{
    collectiveId: string;
    productId: string;
}

enum CollectiveMeetingFrequency
{
    Daily = 1,
    Weekly = 2,
    Monthly = 3,
    Yearly = 4
}

export interface CollectiveCreateModel
{
    distributionChannelId: string;
    name: string;
    geolocationId: string;
    yearOfCreation: number;
    frequency?: CollectiveMeetingFrequency;
    numMaleMembers: number;
    numFemaleMembers: number;
    chairPersonId: string;
    secretaryId?: string;
    treasurerId?: string;
}

export interface CollectivesFilterQuery
{
    collectivesFilter: CollectivesFilter;
    skip?: number;
    take?: number;
}

export interface CollectivesFilterQueryModel
{
    collectives: CollectiveModel[];
    totalCollectives: number;
}

export interface CollectiveGeolocationPayload
{
    collectiveId: string;
    geolocationId: string;
}

export interface CollectivePersonPayload 
{
    collectiveId: string,
    type: 'chairPerson' | 'secretary' | 'treasurer',
    userId: string
};


export interface CollectiveClaimModel 
{
    id?: string;
    userId?: string;
    policyId?: string;
    productName?: string;
    collectiveId?: string;
    collectiveName?: string;
    beneficiaryId?: string;
    beneficiaryName?: string;
    amount: number;
    currency?: string;
    status: number;
    paidAt?: Date;
    createdAt: Date;
    numValidators: number;
    numConfirmations: number;
    maxValidatedAt?: Date;
    hours: number;
}

export enum CollectiveOfficial
{
    ChairPerson = 'chairPerson',
    Secretary = 'secretary',
    Treasurer = 'treasurer'
}

export interface CollectivePersonModel
{
    collectiveId: string;
    type: CollectiveOfficial;
    userId: string;
}


export interface CollectiveMovePayload
{
    collectiveId: string;
    distributionChannelId?: string;
    userId?: string;
}

@Injectable({ providedIn: 'root' })
export class CollectiveRepository
{

    constructor(private queryService: QueryService, private commandService: CommandService) { }

    public getCollectives(data: CollectivesQueryPayload): Promise<IQueryResponse<CollectivesQueryResponse>>
    {
        return this.queryService.queryAsync<CollectivesQueryResponse>('CollectivesQuery', data);
    }


    public getTrainersCollectives(data: TrainersCollectivesQuery): Promise<IQueryResponse<TrainersCollectivesQueryResult>>
    {
        return this.queryService.queryAsync<TrainersCollectivesQueryResult>('TrainersCollectivesQuery', data);
    }

    public getCollective(collectiveId: string): Promise<IQueryResponse<CollectiveQueryModel>>
    {
        return this.queryService.queryAsync('CollectiveQuery', { collectiveId });
    }

    public getCollectiveMembers(collectiveId: string): Promise<IQueryResponse<MemberModel[]>>
    {
        return this.queryService.queryAsync('CollectiveMembersQuery', { collectiveId });
    }

    public async getTrainersCollective(collectiveId: string): Promise<IQueryResponse<TrainersCollectiveQueryResult>>
    {
        return this.queryService.queryAsync('TrainersCollectiveQuery', { collectiveId });
    }

    public getFilteredCollectives(data: CollectivesFilterQuery): Promise<IQueryResponse<CollectivesFilterQueryModel>>
    {
        return this.queryService.queryAsync<CollectivesFilterQueryModel>('CollectivesFilterQuery', data);
    }

    public setCollectiveSetDefaultLevel(data: CollectiveSalesModel): Promise<ICommandResponse> 
    {
        return this.commandService.publishAsync('CollectiveSetDefaultLevel', data);
    }

    public setCollectiveSetGeolocation(data: CollectiveGeolocationPayload): Promise<ICommandResponse>
    {
        return this.commandService.publishAsync('CollectiveSetLocation', data);
    }

    public setCollectiveSetPerson(data: CollectivePersonPayload): Promise<ICommandResponse>
    {
        return this.commandService.publishAsync('CollectiveSetPerson', data);
    }

    public collectiveGenerateSales(data: CollectiveSalesModel): Promise<ICommandResponse> 
    {
        return this.commandService.publishAsync('CollectiveGenerateSales', data);
    }

    public setCollectiveEditSalesDetails(data: CollectiveEditSalesDetailsType): Promise<ICommandResponse> 
    {
        return this.commandService.publishAsync('CollectiveEditSalesDetails', data);
    }

    public create(data: CollectiveCreateModel)
    {
        return this.commandService.publishAsync('CollectiveCreate', data);
    }

    public save(data: any): Promise<ICommandResponse> 
    {
        return this.commandService.publishAsync('CollectiveSave', data);
    }

    public move(data: CollectiveMovePayload): Promise<ICommandResponse> 
    {
        return this.commandService.publishAsync('CollectiveMove', data);
    }
}
